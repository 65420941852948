<template>
  <div class="row pt-1" style="background:#fff; border-radius: 10px; box-shadow: 0.5px 0.866px 4px 0px rgba(2, 8, 2, 0.2); padding-bottom: 25px;">
    <div class="col-md-12">
      <span class="text-left" :class="$style.title">ASSIGNMENTS</span>
      <!-- <span :class="$style.notificationNumber" v-if="lmsHomeworkCount !== 0">{{lmsHomeworkCount}}</span> -->
    </div>
    <div class="col-md-12 col-sm-12 mt-1 align-self-start">
      <div class="row justify-content-between" :class="$style.outNav">
        <div class="col-md-5" style="background:white; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1) !important;">
          <vue-c3 :handler="countHandler" v-show="totalCount > 0"></vue-c3>
        </div>
        <div class="col-md-5" style="background:white; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1) !important;">
          <vue-c3 :handler="countHandler2" v-show="totalCount2 > 0"></vue-c3>
        </div>
        <!-- <div class="col-md-6">
          <vue-c3 :handler="scoreHandler" v-show="totalCount > 0"></vue-c3>
        </div> -->
        <div class="col-md-12 mb-5" v-show="noContent()">
          There is no Assignment.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Vue from 'vue';
  import VueC3 from 'vue-c3';
  import config from '@/config';

  export default {
    name: 'chart',
    data() {
      return {
        countHandler: new Vue(),
        countHandler2: new Vue(),
        scoreHandler: new Vue(),
        scoreHandler2: new Vue(),
        totalCount: null,
        totalCount2: null,
      };
    },
    components: {
      VueC3,
    },
    computed: {
      ...mapGetters(['stats', 'stats2']),
    },
    methods: {
      setCharts() {
        const countOptions = {
          data: {
            columns: [
              // ['Not Started', this.stats.count.notStarted],
              ['Ongoing', this.stats.count.ongoing],
              // ['Completed', this.stats.count.completed],
            ],
            onclick: () => {
              this.$router.push('/assignments');
            },
            type: 'donut',
          },
          legend: {
            show: true,
            position: 'right',
          },
          tooltip: {
            show: false,
          },
          size: {
            height: 200,
          },
          color: {
            pattern: [config.colors.secondaryColor],
          },
          donut: {
            label: {
              format: value => value,
            },
            title: `Total: ${this.stats.count.total}`,
            // contents: () => '<div>Test</div>',
          },
          title: {
            text: 'Group',
          },
          padding: {
            top: 5,
          },
        };
        // const scoreOptions = {
        //   data: {
        //     columns: [
        //       ['Average Score', parseInt(this.stats.avgScore, 10)],
        //     ],
        //     type: 'gauge',
        //   },
        //   title: {
        //     text: 'Average Score',
        //   },
        //   legend: {
        //     show: true,
        //     position: 'right',
        //   },
        //   gauge: {
        //     fullCircle: true,
        //     max: 100,
        //     min: 0,
        //     // startingAngle: 70,
        //     // width: 25,
        //     label: {
        //       format: value => `${value}%`,
        //       show: false,
        //     },
        //   },
        //   size: {
        //     height: 220,
        //   },
        //   color: {
        //     pattern: ['red', 'yellow'],
        //   },
        //   tooltip: {
        //     show: true,
        //   },
        //   padding: {
        //     top: 0,
        //   },
        // };
        const scoreOptions = {
          data: {
            columns: [
              ['Average', parseInt(this.stats.avgScore, 10)],
              ['Remaining', 100 - parseInt(this.stats.avgScore, 10)],
            ],
            onclick: () => {
              this.$router.push('/assignments');
            },
            type: 'donut',
          },
          legend: {
            show: true,
            position: 'right',
          },
          tooltip: {
            show: false,
          },
          size: {
            height: 200,
          },
          color: {
            pattern: [config.colors.primaryColor, '#dee2e6'],
          },
          donut: {
            label: {
              format: value => value,
              show: false,
            },
            title: `${parseInt(this.stats.avgScore, 10)}%`,
          },
          title: {
            text: 'Average Score',
          },
          padding: {
            top: 5,
          },
        };
        this.countHandler.$emit('init', countOptions);
        this.scoreHandler.$emit('init', scoreOptions);
      },
      setCharts2() {
        const countOptions2 = {
          data: {
            columns: [
              // ['Not Started', this.stats2.count.notStarted],
              ['Ongoing', this.stats2.count.ongoing],
              // ['Completed', this.stats2.count.completed],
            ],
            onclick: () => {
              // this.$router.push('/assignments');
              this.$router.push({
                name: 'teacherAssignments',
                params: {
                  type: 'individual',
                },
              });
            },
            type: 'donut',
          },
          legend: {
            show: true,
            position: 'right',
          },
          tooltip: {
            show: false,
          },
          size: {
            height: 200,
          },
          color: {
            pattern: [config.colors.secondaryColor],
          },
          donut: {
            label: {
              format: value => value,
            },
            title: `Total: ${this.stats2.count.total}`,
            // contents: () => '<div>Test</div>',
          },
          title: {
            text: 'Individual',
          },
          padding: {
            top: 5,
          },
        };
        const scoreOptions2 = {
          data: {
            columns: [
              ['Average', parseInt(this.stats2.avgScore, 10)],
              ['Remaining', 100 - parseInt(this.stats2.avgScore, 10)],
            ],
            onclick: () => {
              this.$router.push({
                name: 'teacherAssignments',
                params: {
                  type: 'individual',
                },
              });
            },
            type: 'donut',
          },
          legend: {
            show: true,
            position: 'right',
          },
          tooltip: {
            show: false,
          },
          size: {
            height: 200,
          },
          color: {
            pattern: [config.colors.primaryColor, '#dee2e6'],
          },
          donut: {
            label: {
              format: value => value,
              show: false,
            },
            title: `${parseInt(this.stats2.avgScore, 10)}%`,
          },
          title: {
            text: 'Average Score',
          },
          padding: {
            top: 5,
          },
        };
        this.countHandler2.$emit('init', countOptions2);
        this.scoreHandler2.$emit('init', scoreOptions2);
      },
      noContent() {
        let trueOrFalse = false;
        if (this.totalCount === 0 && this.totalCount2 === 0) {
          trueOrFalse = true;
        }
        return trueOrFalse;
      },
    },
    mounted() {
      this.ready = false;
      this.setCharts();
      this.setCharts2();
      this.totalCount = this.stats.count.total;
      this.totalCount2 = this.stats2.count.total;
      // console.log('this.stats', this.stats);
      this.ready = true;
    },
    watch: {
      stats() {
        this.setCharts();
        this.totalCount = this.stats.count.total;
        this.totalCount2 = this.stats2.count.total;
      },
      stats2() {
        this.setCharts2();
        this.totalCount = this.stats.count.total;
        this.totalCount2 = this.stats2.count.total;
      },
    },
  };
</script>

<style src='./style.less' lang='less' module/>