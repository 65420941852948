<template>
  <div>
    <div class="row mt-1" style="padding:0px; margin-bottom:10px;">
      <div :class="$style.divPlatform" @click="$router.push('/teacherResources')">
        <div :class="[$style.divPlatformBtn, $style.divPlatformBtnTeachersArea]"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import tippy from 'tippy.js';

export default {
  name: 'platforms',
  data() {
    return {
      monitoringSheetsClicked: false,
      kwButtonClicked: false,
      placementButtonClicked: false,
      placementSecondaryCode: '',
      placementHighCode: '',
      querystringtext: '',
      teacherKwCredentials1: '',
      teacherKwCredentials2: '',
      teacherKwCredentials3: '',
      teacherKwCredentials4: '',
      hexagonia5Credentials: '',
      teacherKwReportCredentials: '',
      teacherUesReportCredentials: '',
      aimData: [],
      subAimData: [],
      subAimClicked: false,
      schoolData: {},
    };
  },
  computed: {
    ...mapState(['userDetails', 'username', 'password', 'grade', 'lang']),

  },
  beforeMount() {
    this.setData();
  },
  mounted() {
    tippy('[title]', {
      placement: 'top',
    });
  },
  watch: {
    userDetails() {
      this.setData();
    },
  },
  methods: {
    setData() {
      if (this.userDetails) {
        this.querystringtext = `?username=${btoa(this.username)}&password=${btoa(this.password)}`;
        this.teacherKwCredentials1 = `?username=${btoa('teacherkw00188')}&password=${btoa('abijrs')}`;
        this.teacherKwCredentials2 = `?username=${btoa('teacherkw02715')}&password=${btoa('abijrs')}`;
        this.teacherKwCredentials3 = `?username=${btoa('teacherkw05188')}&password=${btoa('abijrs')}`;
        this.teacherKwCredentials4 = `?username=${btoa('teacherkw07677')}&password=${btoa('abijrs')}`;
        this.hexagonia5Credentials = `?username=${btoa('hexagonia01')}&password=${btoa('hexagonia01')}`;
        this.placementSecondaryCode = `?code=${btoa('secondary')}`;
        this.placementHighCode = `?code=${btoa('high')}`;
        this.teacherUesReportCredentials = this.querystringtext;
      }
    },
    monitoringSheetsModalClosed() {
      this.monitoringSheetsClicked = false;
      this.kwButtonClicked = false;
      this.subAimClicked = false;
      this.placementButtonClicked = false;
    },
  },
};
</script>

<style src='./style.less' lang='less' module/>

<style scoped>
  a {
    text-decoration: none;
    color: black;
  }
</style>
