module.exports = {
  global_key: {
    tokenName: 'access_token',
  },
  colors: {
    primaryColor: '#1127aa',
    secondaryColor: '#d42e12',
    backgroundColor: 'whitesmoke',
  },
  apiKey: 'cr5Ycl8RQSDGlV0RKgKl8tI3GjvVA6Q2FKBBGmD56wFjxSIo',
};
