<template>
<div>
<HeaderCustom v-if="!isHome"></HeaderCustom>
<div class="container">
  <div class="row mt-5" v-if="!isHome">
    <template v-if="!isStudent">
      <div class="col-md-3 mt-5">
        <span>Kidzwonder Grade 2</span>
          <a :href="`https://www.kidzwonder.com${kwquerystingtextDemoLevel2}`" target="_blank">
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKidzwonderPirate]"></div>
            </div>
          </a>
      </div>
      <div class="col-md-3 mt-5">
        <span>Kidzwonder Grade 3</span>
          <a :href="`https://www.kidzwonder.com${kwquerystingtextDemoLevel3}`" target="_blank">
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKidzwonderSpace]"></div>
            </div>
          </a>
      </div>
      <div class="col-md-3 mt-5">
        <span>Kidzwonder Grade 4</span>
          <a :href="`https://www.kidzwonder.com${kwquerystingtextDemoLevel4}`" target="_blank">
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKidzwonderSpace]"></div>
            </div>
          </a>
      </div>
      <div class="col-md-3 mt-5">
        <span>Hexagonia Grade 5</span>
          <a :href="`https://www.hexagonia.com.tr${hexaquerystingtextDemoLevel5}`" target="_blank">
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagoniaSpace]"></div>
            </div>
          </a>
      </div>
      <div class="col-md-3 mt-5">
        <span>Hexagonia Grade 6</span>
          <a :href="`https://www.hexagonia.com.tr${hexaquerystingtextDemoLevel6}`" target="_blank">
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagoniaSpace]"></div>
            </div>
          </a>
      </div>
      <div class="col-md-3 mt-5">
        <span>Hexagonia Grade 7</span>
          <a :href="`https://www.hexagonia.com.tr${hexaquerystingtextDemoLevel7}`" target="_blank">
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagoniaSpace]"></div>
            </div>
          </a>
      </div>
    </template>
    </div>
    <div v-else class="row mt-1" style="margin-left:-45px; margin-right:-45px;">
      <template v-if="isStudent">
        <template v-if="this.level === 'İlkokul' && (Number(grade) === 2 || Number(grade) === 3)">
          <div class="col-md-12 mt-1">
            <a :href="`https://www.kidzwonder.com${querystingtext}`" target="_blank">
              <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKidzwonderSpace2]"></div>
              </div>
            </a>
          </div>
          
        </template>
        <template v-else-if="this.level === 'İlkokul' && (Number(grade) === 4)">
          <div class="col-md-12 mt-1">
            <a :href="`https://www.hexagonia.com.tr${hexaquerystingtext}`" target="_blank">
                <div :class="$style.divPlatform">
                  <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagonia]"></div>
                </div>
              </a>
          </div>
        </template>
        <template v-else-if="this.level === 'Ortaokul'">         

          <div class="col-md-12 mt-3" v-if="Number(grade) === 7 ">
        <!--href="https://app.newsomatic.net/login"--->
            <a @click="nom()" target="_blank">
              <div :class="$style.divPlatform">
                <div :class="[$style.divPlatformBtn, $style.divPlatformBtnNewsomatic]"></div>
                <!-- <div :class="$style.txtPlatform">CLASSROOM</div> -->
              </div>
            </a>
          </div>

          <div class="col-md-12 mt-3" v-if="Number(this.grade) >= 4 && Number(this.grade) <= 6"> 
          <a @click="RLP()" target="_blank">
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLP]"></div>
            </div>
          </a>
          </div>

          <div class="col-md-12 mt-3" v-if="Number(grade) === 5 || Number(grade) === 6">
            <a>
                <div :class="$style.divPlatform" @click="checkHll()">
                <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHighlights]"></div>
              </div>
            </a>
          </div>

        </template>
        <template v-else>
          <!-- <div class="col-md-12 mt-1">
            <a :href="`https://meet.yourlearningplace.com/myeducams`" target="_blank">
              <div :class="$style.divPlatform">
                <div :class="[$style.divPlatformBtn, $style.divPlatformBtnMyEduCams2]"></div>
              </div>
            </a>
          </div> -->
        </template>
      </template>
      <template v-else>
        <div class="col-md-6 mt-1">
          <div :class="$style.divPlatform" @click="$router.push('/platforms')">
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnContents]"></div>
          </div>
        </div>

        <div class="col-md-6 mt-1"> 
          <a :href="`https://richmondlp.com/login`" target="blank">
            <div :class="$style.divPlatform">
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLP]"></div>
            </div>
          </a>
        </div>



        <div class="col-md-12 mt-3" v-if="Number(grade) === 7 ">
        <!--href="https://app.newsomatic.net/login"--->
            <a @click="nom()" target="_blank">
              <div :class="$style.divPlatform">
                <div :class="[$style.divPlatformBtn, $style.divPlatformBtnNewsomatic]"></div>
                <!-- <div :class="$style.txtPlatform">CLASSROOM</div> -->
              </div>
            </a>
          </div>
        <!-- <div class="col-md-4 mt-1">
            <a :href="`https://meet.yourlearningplace.com/myeducams`" target="_blank">
              <div :class="$style.divPlatform">
                <div :class="[$style.divPlatformBtn, $style.divPlatformBtnMyEduCams2]"></div>
              </div>
            </a>
        </div> -->
        <div class="col-md-6 mt-1" @click="query()" data-toggle="modal" data-target="#exampleModalCenter">
          <!-- <a :href="`https://www.uesportals.com/#/login/signin${querystingReporttext}`" target="_blank"> -->
            <div :class="$style.divPlatform">
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnReports]"></div>
            </div>
          <!-- </a> -->
        </div>
        <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Select Your Report Platform</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12" v-if="this.level !== 'Ortaokul'">
                    <div class="mt-1">
                      <a :href="`https://www.uesportals.com/#/login/signin${querystingKidzReport}`" target="_blank">
                        <div :class="$style.divPlatform">
                          <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKidzwonder]"></div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="col-md-12" v-if="this.level === 'Ortaokul'">
                    <div class="mt-1">
                      <a :href="`https://www.uesportals.com/#/login/signin${querystingHexaReport}`" target="_blank">
                        <div :class="$style.divPlatform">
                          <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagonia]"></div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <!-- <div class="col-md-12 mt-1">
                    <a :href="`https://report.myeduassessment.com/#/login${querystingtext}`" target="_blank">
                      <div :class="$style.divPlatform">
                      <div :class="[$style.divPlatformBtn, $style.divPlatformBtnAssessment2]"></div>
                      </div>
                    </a>
                  </div> -->
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
</div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import HeaderCustom from '@/components/HeaderCustom';
import swal from 'sweetalert';
import CryptoJS from 'crypto-js';
// import schoolsArr from '../../../static/js/newSchool';


export default {
  name: 'platforms',
  data() {
    return {
      grade: '',
      level: '',
      roleType: '',
      querystingtext: '',
      kwquerystingtext: '',
      hexaquerystingtext: '',
      kwquerystingtextDemoLevel1: '',
      kwquerystingtextDemoLevel2: '',
      kwquerystingtextDemoLevel3: '',
      kwquerystingtextDemoLevel4: '',
      hexaquerystingtextDemoLevel5: '',
      hexaquerystingtextDemoLevel6: '',
      hexaquerystingtextDemoLevel7: '',
      // karnevalstingtextDemoLevel2: '',
      // karnevalstingtextDemoLevel3: '',
      // karnevalstingtextDemoLevel4: '',
      querystingHexaReport: '',
      querystingKidzReport: '',
      querystingFnlReport: '',
      querystingKarnReport: '',
      studentAimCode: '',
    };
  },
  components: {
    HeaderCustom,
  },
  props: ['isHome', 'isStudent'],
  computed: {
    ...mapState(['userDetails', 'username', 'password', 'collections']),
    levelName() {
      return this.$store.state.userDetails.organization.find(x => x.type === 'level').name;
    },
  },
  beforeMount() {

   

    this.setData();
    this.kwquerystingtextDemoLevel1 = `?username=${btoa('uesdemo1a')}&password=${btoa('123456')}`;
    this.kwquerystingtextDemoLevel2 = `?username=${btoa('uesdemo2a')}&password=${btoa('123456')}`;
    this.kwquerystingtextDemoLevel3 = `?username=${btoa('uesdemo3a')}&password=${btoa('123456')}`;
    this.kwquerystingtextDemoLevel4 = `?username=${btoa('uesdemo4a')}&password=${btoa('123456')}`;
    this.hexaquerystingtextDemoLevel5 = `?username=${btoa('uesdemo5a')}&password=${btoa('123456')}`;
    this.hexaquerystingtextDemoLevel6 = `?username=${btoa('uesdemo6a')}&password=${btoa('123456')}`;
    this.hexaquerystingtextDemoLevel7 = `?username=${btoa('uesdemo7a')}&password=${btoa('123456')}`;
    this.hexaquerystingtextDemoLevel5de = `?username=${btoa('uesdemo5ade')}&password=${btoa('123456')}`;
    this.hexaquerystingtextDemoLevel6de = `?username=${btoa('finaldemo6ade')}&password=${btoa('123456')}`;
    this.hexaquerystingtextDemoLevel7de = `?username=${btoa('finaldemo7ade')}&password=${btoa('123456')}`;
    // this.karnevalstingtextDemoLevel2 = `?username=${btoa('uesdemo1ade')}&password=${btoa('123456')}`;
    // this.karnevalstingtextDemoLevel3 = `?username=${btoa('uesdemo3ade')}&password=${btoa('123456')}`;
    // this.karnevalstingtextDemoLevel4 = `?username=${btoa('uesdemo4ade')}&password=${btoa('123456')}`;
  },
  watch: {
    userDetails() {
      this.setData();
    },
  },
  methods: {
    setData() {
      if (this.userDetails) {
        this.level = this.userDetails.organization.find(x => x.type === 'level') ? this.userDetails.organization.find(x => x.type === 'level').name : null;
        this.grade = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[0] : 0;
        this.hidePlatform = this.userDetails.organization.find(x => x.type === 'grade').name.split(' ')[1];
        this.roleType = this.userDetails.role_type;
        this.querystingtext = `?username=${btoa(this.username)}&password=${btoa(this.password)}`;
        if (this.userDetails.lang === 'en') {
          // this.hexaquerystingtext = `?username=${btoa('steve.jobs')}&password=${btoa('090807')}`;
          this.hexaquerystingtext = this.querystingtext;
        } else {
          this.hexaquerystingtext = this.querystingtext;
        }
        this.kwquerystingtext = this.querystingtext;
        // const campus = this.userDetails.organization.find(x => x.type === 'main');
        // const school = schoolsArr.find(x => x.school_id === campus.id);
      }
    },
    query() {
      this.querystingHexaReport = `?username=${btoa(this.username)}&password=${btoa(this.password)}&platformId=${btoa('4')}`;
      this.querystingKidzReport = `?username=${btoa(this.username)}&password=${btoa(this.password)}&platformId=${btoa('23')}`;
      this.querystingUesReport = `?username=${btoa(this.username)}&password=${btoa(this.password)}&platformId=${btoa('1')}`;
      this.querystingKarnReport = `?username=${btoa(this.username)}&password=${btoa(this.password)}&platformId=${btoa('5')}`;
    },

    nom() {
      
        // this.newsomaticSchool = this.userDetails.compass_school;
        this.newsomaticUser = this.userDetails.compass_username != null ? atob(this.userDetails.compass_username) : '';
        this.newsomaticPass = this.userDetails.compass_password != null ? atob(this.userDetails.compass_password) : '';

        if(this.roleType === 'teacher'){
          window.open('https://app.newsomatic.net/login', '_blank');
        }else{
          if(this.newsomaticUser != ''){
            swal({
              title: 'News-o-Matic',
              html:`        
              <div class="col-md-12 mt-2 text-center" style="display:table-cells" v-else> <!-- Eğer uesDigital kodu girilmişse -->
                          <div class="alert alert-info" role="alert">
                            Aşağıdaki kullanıcı bilgileri ile Go to News-o-Matic linkine tıklayarak hesabınıza giriş yapabilirsiniz.
                          </div>
                          <table class="table table-sm table-striped">
                            <tbody>
                              <tr>
                                <td class="table-primary"><b>User name</b></td>
                                <td class="table-light"><b>${this.newsomaticUser}</b></td>
                              </tr>
                              <tr>
                                <td class="table-primary"><b>Password</b></td>
                                <td class="table-light"><b>${this.newsomaticPass}</b></td>
                              </tr>
                            </tbody>
                          </table>
                      </div>`,
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Go to News-o-matic',
            }).then((result) => {
              if (result.value) {
                window.open('https://app.newsomatic.net/login', '_blank');
              }
            });

          }else {
            swal({
              title:'Uyarı!',
              text: 'Kullanıcı bilgileriniz en kısa sürede burada görünecektir...',
              confirmButtonText: 'Kapat',
            });
          }


        }

    },

    RLP() {
      // console.log('sdsds')
      try {
        this.ready = false;
        const secretKey = "VswFYGzh2k45N7Q8R9@TBuCVexFY.G2J3K4N6p7Q%9SATB-VdWeXFZHJ3m5N";
        const userData = {}
        if (this.userDetails) {
          userData.username = this.userDetails.email;
          userData.first_name = this.userDetails.first_name;
          userData.last_name = this.userDetails.last_name;
          userData.role_type = this.userDetails.role_type;
          userData.school_id =  this.userDetails.organization.find(x => x.type === 'main').id;
          userData.campus_id =  this.userDetails.organization.find(x => x.type === 'campus').id;
          userData.campus_name = this.userDetails.organization.find(x => x.type === 'campus').name;
          userData.class = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[0] : 0;
          userData.branch = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[1] : '';
          userData.user_id = this.userDetails.user_id;
        }
        //  console.log(userData)

        const textJson = JSON.stringify(userData);
        const uriText = decodeURIComponent(textJson)
        const ciphertext = CryptoJS.AES.encrypt(uriText, secretKey).toString();
        this.ready = true;
        window.open('https://service.ueslms.com/#/rlp/?sso=' + btoa(ciphertext));
        

      } catch (error) {
        console.log('err', error);
        swal('Giriş Yapılamadı!', 'Bir Hata Oluştu', 'error');
        this.ready = true;
      }
    },

    async checkHll() {
      try {
        this.ready = false;
        const userDetails = await this.userDetails;
        const userId = userDetails.user_id;
        const firstName = userDetails.first_name;
        const lastName = userDetails.last_name;
        // const className = userDetails.organization.find(x => x.type === 'grade').name;
        const userType = userDetails.role_type;
        // const campusId = userDetails.organization.find(y => y.type === 'campus').id;
        const key = userDetails.organization.find(y => y.type === 'campus').hll_company_code;
        // console.log('key', key);
        // const key = obj.find(x => x.schoolId === campusId).companyKey;

        if(userDetails.role_type === "student"){
          const className = encodeURIComponent(userDetails.organization.find(x => x.type === 'grade').name);
          window.go_hll(userId, userId, firstName, lastName, className, userType, key);
        }else if(userDetails.role_type === "teacher"){
          const classes = [];
          await userDetails.organization.filter(x => x.type === 'grade').forEach((item) => {
            classes.push(encodeURIComponent(item.name))
          });
          window.go_hll(userId, userId, firstName, lastName, classes, userType, key);
        }
        setTimeout(() => {
          this.ready = true;
        }, 1000);
      } catch (error) {
        console.log('err', error);
        swal('Giriş Yapılamadı!', 'Bir Hata Oluştu', 'error');
        this.ready = true;
      }
    },
  },
};
</script>

<style src='./style.less' lang='less' module/>

<style scoped>
  a {
    text-decoration: none;
    color: black;
  }
</style>
